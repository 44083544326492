import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllGame(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/all/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGames(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/competition/allWithGroups/${queryParams.page}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompany() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/company/all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGameById(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/competition/allWithGroups/1`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCompetition(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/competition/add`, { competition: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGame(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSetGameResult(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/${params.id}/settimeresults/${params.setId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSetResult(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/${params.id}/settimeresults`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSets(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/${params.id}/settime`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPerformedGroup(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/${params.id}/group?from=${params.from}&to=${params.to}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPerformedTask(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/${params.id}/performedtasks?limit=10`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategory() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/templates-category/all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTemplates(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/templates/all?category_id=${params.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTemplatesTask(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/templates-task/all?template_id=${params.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addOnboarding(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/game/onboarding`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addOnboardingGroupUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/game/onboarding-user`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
