export default [
  {
    header: 'Game Changer',
  },
  {
    title: 'Company',
    route: 'company',
    icon: 'CopyIcon',
  },
  {
    title: 'User',
    route: 'user',
    icon: 'UserIcon',
  },
  {
    title: 'Game',
    route: 'game-list',
    icon: 'CheckCircleIcon',
  },
  {
    title: 'Activity',
    route: 'activity',
    icon: 'ActivityIcon',
  },
  {
    title: 'KPI',
    route: 'kpi',
    icon: 'ClockIcon',
  },
  {
    title: 'Activity Template',
    route: 'activity-templa',
    icon: 'GridIcon',
  },
  {
    title: 'Media',
    route: 'media',
    icon: 'MonitorIcon',
  },
  {
    title: 'Reports',
    route: 'reports',
    icon: 'PieChartIcon',
  },
]
