<template>
  <b-row>
    <b-col cols="12">
      <web-heading />
    </b-col>
    <b-col cols="12">
      <onboarding-wizard />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import OnboardingWizard from './OnboardingWizard.vue'
import WebHeading from '../utility/WebHeading.vue'

export default {
  components: {
    BRow,
    BCol,
    OnboardingWizard,
    WebHeading,
  },
}
</script>
