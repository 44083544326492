<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-primary invoice-logo">
                    Add Team
                  </h3>
                </div>
              </div>
            </div>

          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <b-card-body class="invoice-padding pt-0">

            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Game Name"
                      label-for="mc-game-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Game Name"
                        rules="required"
                      >
                        <b-form-input
                          id="mc-game-name"
                          v-model="competition.c_string_swe"
                          placeholder="game name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      label="Company"
                      label-for="h-company"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="company"
                        rules="required"
                      >
                        <v-select
                          v-model="competition.companyId"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          placeholder="Company"
                          label="companyname"
                          class="flex-grow-1"
                          :close-on-select="false"
                          :options="company"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="Start Date"
                      label-for="h-strat-date"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="start date"
                        rules="required"
                      >
                        <flat-pickr
                          id="h-strat-date"
                          v-model="competition.startdate"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="End Date"
                      label-for="h-end-date"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="end date"
                        rules="required"
                      >
                        <flat-pickr
                          id="h-end-date"
                          v-model="competition.enddate"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label="Vision statement"
                      label-for="mc-vision-statement"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="vision statement"
                        rules="required"
                      >
                        <b-form-input
                          id="mc-vision-statement"
                          v-model="competition.visionstatement"
                          placeholder="Vision statement"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group>
                      <b-form-checkbox
                        id="checkbox-10"
                        v-model="competition.show_welcomePanel"
                        name="checkbox-10"
                      >
                        Show welcome panel
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <!-- submit and reset -->
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      Reset
                    </b-button>
                  </b-col>

                </b-row>
              </b-form>
            </validation-observer>

          </b-card-body>

        </b-card>
      </b-col>

    </b-row>

  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BForm, BFormGroup, BFormInput, BFormCheckbox, 
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import router from '@/router'
import gameStore from '@/store/game/gameStore'
import constants from '@/constants/static.json'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    flatPickr,
    vSelect,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      competition: {
        B: 220,
        G: 123,
        ID: 0,
        R: 18,
        c_string_swe: '',
        color: '#127bdc',
        companyId: '',
        enddate: '',
        show_welcomePanel: false,
        startdate: '',
        use_external_baseline: 1,
        visionstatement: '',
      },
      required,
    }
  },
  setup() {
    const { APP_STORE_MODULE } = constants

    // Register module
    if (!store.hasModule(APP_STORE_MODULE)) store.registerModule(APP_STORE_MODULE, gameStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE)) store.unregisterModule(APP_STORE_MODULE)
    })

    const company = ref([])
    const gameRecord = ref([])

    store.dispatch('app-game/fetchCompany').then(response => { company.value = response.data })
    store.dispatch('app-game/fetchGameById', { id: router.currentRoute.params.id }).then(response => { gameRecord.value = response.data })

    return {
      company,
      gameRecord,
    }
  },
  methods: {
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.competition.companyId = this.competition.companyId.companyID

          store.dispatch('app-game/addCompetition', this.competition)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'You have successfully create competition!',
                },
              })
              this.$router.push({ name: 'game-list' })
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
