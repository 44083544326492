var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-add-wrapper"},[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12"}},[_c('b-card',{staticClass:"invoice-preview-card",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"invoice-padding pb-0"},[_c('div',{staticClass:"d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"},[_c('div',[_c('div',{staticClass:"logo-wrapper"},[_c('logo'),_c('h3',{staticClass:"text-primary invoice-logo"},[_vm._v(" Add Team ")])],1)])])]),_c('hr',{staticClass:"invoice-spacing"}),_c('b-card-body',{staticClass:"invoice-padding pt-0"},[_c('validation-observer',{ref:"validationRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Game Name","label-for":"mc-game-name"}},[_c('validation-provider',{attrs:{"name":"Game Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-game-name","placeholder":"game name"},model:{value:(_vm.competition.c_string_swe),callback:function ($$v) {_vm.$set(_vm.competition, "c_string_swe", $$v)},expression:"competition.c_string_swe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Company","label-for":"h-company"}},[_c('validation-provider',{attrs:{"name":"company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"Company","label":"companyname","close-on-select":false,"options":_vm.company},model:{value:(_vm.competition.companyId),callback:function ($$v) {_vm.$set(_vm.competition, "companyId", $$v)},expression:"competition.companyId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Start Date","label-for":"h-strat-date"}},[_c('validation-provider',{attrs:{"name":"start date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"h-strat-date"},model:{value:(_vm.competition.startdate),callback:function ($$v) {_vm.$set(_vm.competition, "startdate", $$v)},expression:"competition.startdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"End Date","label-for":"h-end-date"}},[_c('validation-provider',{attrs:{"name":"end date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"h-end-date"},model:{value:(_vm.competition.enddate),callback:function ($$v) {_vm.$set(_vm.competition, "enddate", $$v)},expression:"competition.enddate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Vision statement","label-for":"mc-vision-statement"}},[_c('validation-provider',{attrs:{"name":"vision statement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-vision-statement","placeholder":"Vision statement"},model:{value:(_vm.competition.visionstatement),callback:function ($$v) {_vm.$set(_vm.competition, "visionstatement", $$v)},expression:"competition.visionstatement"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"checkbox-10","name":"checkbox-10"},model:{value:(_vm.competition.show_welcomePanel),callback:function ($$v) {_vm.$set(_vm.competition, "show_welcomePanel", $$v)},expression:"competition.show_welcomePanel"}},[_vm._v(" Show welcome panel ")])],1)],1),_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Submit ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }