import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  congratulations: {
    name: 'John',
    saleToday: '48900',
  },
  statisticsItems: [
    {
      icon: 'WatchIcon',
      color: 'light-primary',
      title: '2022-Sep-01',
      subtitle: 'Game start date',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'WatchIcon',
      color: 'light-info',
      title: '2022-Sep-30',
      subtitle: 'Game end date',
      customClass: 'mb-2 mb-xl-0',
    },
  ],
  statisticsOrder: {
    series: [
      {
        name: '2020',
        data: [45, 85, 65, 45, 65],
      },
    ],
  },
  statisticsProfit: {
    series: [
      {
        data: [0, 20, 5, 30, 15, 45],
      },
    ],
  },
  earningsChart: {
    series: [53, 16, 31],
  },
  revenue: {
    years: ['2020', '2019', '2018'],
    price: '25,852',
    budget: '56,800',
    revenueReport: {
      series: [
        {
          name: 'Earning',
          data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
        },
        {
          name: 'Expense',
          data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
        },
      ],
    },
    budgetChart: {
      series: [
        {
          data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
        },
        {
          data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
        },
      ],
    },
  },
  companyTable: [
    {
      avatarImg: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
      title: 'Niklas Wörmann',
      subtitle: 'niklas.wormann@bisevo.se',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-primary',
      avatarTitle: '352',
      viewTitle: '1',
      viewsub: 'in 5 min',
      revenue: '891.2',
      sales: '68',
      loss: true,
    },
    {
      avatarImg: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
      title: 'Peter Sjögren',
      subtitle: 'peter.sjögren@bisevo.se',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-success',
      avatarTitle: '330',
      viewTitle: '2',
      viewsub: 'in 5 min',
      revenue: '668.51',
      sales: '97',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
      title: 'Pär Ågerfalk',
      subtitle: 'pär.Ågerfalk@bisevo.se',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: '300',
      viewTitle: '3',
      viewsub: 'in 5 min',
      revenue: '522.29',
      sales: '62',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
      title: 'Claire Ingram',
      subtitle: 'claire.ingram@bisevo.se',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-primary',
      avatarTitle: '290',
      viewTitle: '4',
      viewsub: 'in 5 min',
      revenue: '291.01',
      sales: '88',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
      title: 'Darek Hafto',
      subtitle: 'darek.hafto@bisevo.se',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-success',
      avatarTitle: '270',
      viewTitle: '5',
      viewsub: 'in 5 min',
      revenue: '783.93',
      sales: '16',
      loss: true,
    },
    {
      avatarImg: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
      title: 'Owen Eriksson',
      subtitle: 'owen.eriksson@bisevo.se',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: '260',
      viewTitle: '6',
      viewsub: 'in 5 min',
      revenue: '780.05',
      sales: '78',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
      title: 'Fredrik Bengtsson',
      subtitle: 'fredrik.bengtsson@bisevo.se',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: '250',
      viewTitle: '7',
      viewsub: 'in 5 min',
      revenue: '531.49',
      sales: '42',
      loss: false,
    },
  ],
  meetup: {
    mediaData: [
      { avatar: 'CalendarIcon', title: 'Sat, May 25, 2020', subtitle: '10:AM to 6:PM' },
      { avatar: 'MapPinIcon', title: 'Central Park', subtitle: 'Manhattan, New york City' },
    ],
    avatars: [
      { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper' },
    ],
  },
  goalOverview: {
    completed: '786,617',
    inProgress: '13,561',
    series: [83],
  },
  transactionData: [
    {
      mode: 'Wallet',
      types: 'Starbucks',
      avatar: 'PocketIcon',
      avatarVariant: 'light-primary',
      payment: '-$74',
      deduction: true,
    },
    {
      mode: 'Bank Transfer',
      types: 'Add Money',
      avatar: 'CheckIcon',
      avatarVariant: 'light-success',
      payment: '+$480',
      deduction: false,
    },
    {
      mode: 'Paypal',
      types: 'Add Money',
      avatar: 'DollarSignIcon',
      avatarVariant: 'light-danger',
      payment: '+$480',
      deduction: false,
    },
    {
      mode: 'Mastercard',
      types: 'Ordered Food',
      avatar: 'CreditCardIcon',
      avatarVariant: 'light-warning',
      payment: '-$23',
      deduction: true,
    },
    {
      mode: 'Transfer',
      types: 'Refund',
      avatar: 'TrendingUpIcon',
      avatarVariant: 'light-info',
      payment: '+$98',
      deduction: false,
    },
  ],
}
/* eslint-disable global-require */
mock.onGet('/leaderboard/data').reply(() => [200, data])
