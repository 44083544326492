<template>
  <!-- form -->
  <validation-observer ref="rules">
    <b-form>
      <b-card
        no-body
        class="card-payment"
      >
        <b-card-body>
          <!-- form -->
          <b-form>
            <b-row>

              <b-col cols="12">
                <b-form-group
                  label="Team Name"
                  label-for="i-teamname"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Team Name"
                    rules="required"
                  >
                    <b-form-input
                      id="i-teamname"
                      v-model="group.groupname"
                      placeholder="Team C"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="First Name"
                  label-for="i-firstname"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-input
                      id="i-firstname"
                      v-model="user.firstname"
                      placeholder="Niklas"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Last Name"
                  label-for="i-lastname"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <b-form-input
                      id="i-lastname"
                      v-model="user.lastname"
                      placeholder="Wörmann"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Email Address"
                  label-for="i-email"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="i-email"
                      v-model="user.email"
                      placeholder="user@bisevo.se"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                lg="12"
                md="8"
              >
                <b-button
                  variant="primary"
                  class="mr-50"
                  @click.prevent="submitForm"
                >
                  {{ buttonText }}
                </b-button>
                <b-button
                  variant="outline-secondary"
                  type="reset"
                  @click.prevent="resetForm"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <!--/ form -->
        </b-card-body>
      </b-card>
    </b-form>
  </validation-observer>
</template>
<script>
import {
  BCard, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import gameStore from '@/store/game/gameStore'
import constants from '@/constants/static.json'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    competitionId: {
      type: String,
      default: () => 0,
    },
    competitionGroup: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      group: {
        groupID: '0',
        groupname: '',
        competitionID: '',
      },
      user: {
        firstname: '',
        lastname: '',
        email: '',
      },
      buttonText: 'Join New Team',
      email,
      required,
    }
  },
  setup() {
    const { APP_STORE_MODULE } = constants
    // Register module
    if (!store.hasModule(APP_STORE_MODULE)) store.registerModule(APP_STORE_MODULE, gameStore)

    // UnRegister module
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE)) store.unregisterModule(APP_STORE_MODULE)
    })
  },
  watch: {
    competitionGroup: {
      handler() {
        if (this.competitionGroup) {
          this.group.groupID = this.competitionGroup.groupID
          this.group.groupname = this.competitionGroup.groupname
          this.buttonText = `Join ${this.group.groupname}`
        }
      },
    },
  },
  methods: {
    submitForm() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.group.user = this.user
          this.group.competitionID = this.competitionId
          this.onboardingNewGroup()
        }
      })
    },
    onboardingNewGroup() {
      store.dispatch('app-game/addOnboardingGroupUser', this.group)
        .then(() => {
          this.group = ''
          this.user = ''
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              icon: 'EditIcon',
              variant: 'success',
              text: 'You have successfully create user!',
            },
          })
          this.$router.go(this.$router.currentRoute)
        })
        .catch(error => {
          if (error.response.data.data.status === 'failed') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                icon: 'EditIcon',
                variant: 'light-danger',
                text: error.response.data.data.message.toString(),
              },
            })
          }
        })
    },
    resetForm() {
      this.buttonText = 'Join New Team'
      this.group.groupID = 0
      this.group.groupname = ''
    },
  },
}
</script>
