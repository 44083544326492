<template>
  <b-card-code title="Recently performed tasks">
    <b-carousel
      id="carousel-interval"
      controls
      :interval="3000"
    >
      <b-carousel-slide
        v-for="(slide, index) in slides"
        :key="index"
        img-blank
      >

        <b-list-group>
          <b-list-group-item
            active
            class="flex-column align-items-start"
          >
            <div class="d-flex w-50 justify-content-between">
              <h5 class="mb-1 text-white">
                {{ slide.firstname + ' ' + slide.lastname }}
              </h5>
              <small class="text-secondary">{{ slide.datetime }}</small>
            </div>
            <b-card-text class="mb-1">
              {{ slide.habit }}
            </b-card-text>
            <small class="text-secondary">(habit)</small>
          </b-list-group-item>

          <b-list-group-item class="flex-column align-items-start">
            <small class="text-secondary">{{ getSubTaskString(slide.subTasks) }}</small>
          </b-list-group-item>
        </b-list-group>
      </b-carousel-slide>
    </b-carousel>
  </b-card-code>

</template>

<script>
import {
  BCarousel, BCarouselSlide, BCardText, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import gameStore from '@/store/game/gameStore'
import constants from '@/constants/static.json'

export default {
  components: {
    BCardCode,
    BCarousel,
    BCarouselSlide,
    BCardText,
    BListGroup,
    BListGroupItem,
  },
  data: () => ({
    slides: [],
    timer: '',
  }),
  setup() {
    const { APP_STORE_MODULE } = constants
    // Register module
    if (!store.hasModule(APP_STORE_MODULE)) store.registerModule(APP_STORE_MODULE, gameStore)
    // UnRegister on game
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE)) store.unregisterModule(APP_STORE_MODULE)
    })
  },
  created() {
    this.generateReport()
    this.timer = setInterval(this.generateReport, constants.RELOAD_TIME)
  },
  methods: {
    generateReport() {
      const { id } = router.currentRoute.params
      if (id) {
        store.dispatch('app-game/fetchPerformedTask', { id }).then(task => {
          if (task.data) {
            this.slides = task.data
          }
        })
      }
    },
    getSubTaskString(data) {
      let results = ''
      data.forEach((item, index) => {
        if (index === 0) {
          results = item.subtaskstring
        } else {
          results = `${results} | ${item.subtaskstring}`
        }
      })
      return results
    },
  },
}

</script>

<style lang="scss">

.carousel-item {
  height: 230px;
}

</style>
