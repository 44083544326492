<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="SetTableColumns"
      empty-text="No matching records found"
      primary-key="id"
      class="mb-0"
    >

      <!-- place -->
      <template #cell(place)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.place }}</span>
        </div>
      </template>

      <!-- group name -->
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            :style="backgroundColor(data.item.color)"
            class="mr-1"
          />
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.groupname }}</span>
            </div>
          </div>
        </div>
      </template>

      <!-- victorypoints -->
      <template #cell(xp)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.points }}</span>
        </div>
      </template>
      <!-- batch -->
      <template #cell(badge)="data">
        <div class="d-flex align-items-center">

          <div class="demo-inline-spacing">
            <b-avatar
              v-for="batch in data.item.batchs"
              :key="batch.batchID"
              badge-variant="success"
              :badge="batch.count.toString()"
              :src="batchUrl(batch.batchID)"
            />
          </div>
        </div>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable, BAvatar } from 'bootstrap-vue'
import Leaderboard from './Leaderboard'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const {
      SetTableColumns,
      backgroundColor,
      batchUrl,
    } = Leaderboard()

    return {
      SetTableColumns,
      backgroundColor,
      batchUrl,
    }
  },

}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
