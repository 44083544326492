<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <b-row>
      <b-col cols="12">
        <web-heading />
      </b-col>
    </b-row>
    <b-card-body>
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <h3 class="mb-0">
            >>
          </h3>
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ game.name }}
          </b-card-title>
          <b-card-text class="mb-0">
            {{ game.visionstatement }}
          </b-card-text>
        </div>
      </div>
      <!--/ metting header -->
      <!-- start date -->
      <b-media
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ game.startdate }}
          </h6>
          <small>{{ game.starttime }}</small>
        </b-media-body>
      </b-media>
      <b-media
        no-body
      >
        <!-- end date -->
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ game.enddate }}
          </h6>
          <small>{{ game.endtime }}</small>
        </b-media-body>
      </b-media>
      <!-- avatar group -->
      <b-avatar-group
        class="mt-2 pt-50"
        size="33"
      >
        <h6 class="align-self-center cursor-pointer ml-1 mb-0">
          +{{ game.usercount }} users
        </h6>
      </b-avatar-group>
    </b-card-body>
    <b-row>
      <b-col
        v-for="(group,index) in game.groups"
        :key="index"
        lg="4"
        md="6"
        :title="group.groupname"
      >
        <b-card class="card-app-design">

          <!-- design group -->
          <div class="design-group">
            <h6 class="section-label">
              Team
            </h6>
            <b-badge
              :key="index"
              :variant="changeVariant(index)"
              class="mr-1"
            >
              {{ group.groupname }}
            </b-badge>
          </div>
          <div class="design-group">
            <h6 class="section-label">
              Members
            </h6>
            <b-badge
              v-for="(user,userIndex) in group.users"
              :key="userIndex"
              :variant="changeVariant(index)"
              class="mr-1"
            >
              {{ user.firstname + ' ' + user.lastname.charAt(0) }}
            </b-badge>
          </div>
          <!--/ design group -->
          <!-- button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="changeButtonVariant(index)"
            @click="joinTeam(group)"
          >
            Join Team
          </b-button>
        </b-card>
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <onboarding-new-group
          :competition-group="competitionGroup"
          :competition-id="competitionID"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BButton,
  BAvatar, BAvatarGroup, VBTooltip, BMediaBody, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import WebHeading from '../utility/WebHeading.vue'
import router from '@/router'
import store from '@/store'
import constants from '@/constants/static.json'
import gameStore from '@/store/game/gameStore'
import OnboardingNewGroup from './OnboardingNewGroup.vue'

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BRow,
    BCol,
    BBadge,
    BButton,
    OnboardingNewGroup,
    WebHeading,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      game: {
        name: '',
        startdate: '',
        enddate: '',
        starttime: '',
        endtime: '',
        visionstatement: '',
        usercount: '0',
        groups: [],
      },
      competitionID: '',
      competitionGroup: {},
    }
  },
  setup() {
    const { APP_STORE_MODULE } = constants
    // Register module
    if (!store.hasModule(APP_STORE_MODULE)) store.registerModule(APP_STORE_MODULE, gameStore)
    // UnRegister on game
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE)) store.unregisterModule(APP_STORE_MODULE)
    })
  },
  created() {
    this.fetchAllGame()
  },
  methods: {
    fetchAllGame() {
      if (router.currentRoute.params.id) {
        this.competitionID = router.currentRoute.params.id
        store.dispatch('app-game/fetchAllGame', this.competitionID).then(response => {
          if (response.data) {
            const { data } = response.data
            this.game.name = data.c_string_swe
            this.game.visionstatement = data.visionstatement
            this.game.groups = data.groups
            this.game.startdate = moment(data.startdate).format(constants.DATE_TIME.DASH)
            this.game.enddate = moment(data.enddate).format(constants.DATE_TIME.DASH)
            this.game.starttime = moment(data.startdate).format(constants.DATE_TIME.TIME_SEC)
            this.game.endtime = moment(data.enddate).format(constants.DATE_TIME.TIME_SEC)
            this.userCount(data)
          }
        })
      }
    },
    changeVariant(index) {
      const variantList = ['light-success', 'light-primary', 'light-warning', 'light-info', 'light-secondary', 'light-danger']
      const modIndex = index % variantList.length
      return variantList[modIndex]
    },
    changeButtonVariant(index) {
      const variantList = ['success', 'primary', 'warning', 'info', 'secondary', 'danger']
      const modIndex = index % variantList.length
      return variantList[modIndex]
    },
    userCount(data) {
      let count = 0
      data.groups.forEach(group => {
        group.users.forEach(() => {
          // eslint-disable-next-line no-plusplus
          count++
        })
      })
      this.game.usercount = count
    },
    joinTeam(data) {
      this.competitionGroup = data
    },
  },
}
</script>
