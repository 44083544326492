<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="TasksTableColumns"
      class="mb-0"
    >
      <!-- place -->
      <template #cell(place)="data">
        <div class="flex-column">
          <span class="font-weight-bolder mr-1">{{ data.item.place }}</span>
          <b-avatar
            v-if="data.item.trending"
            rounded
            size="42"
            variant="light-success"
          >
            <feather-icon
              :icon="data.item.loss ? 'TrendingDownIcon':'TrendingUpIcon'"
              :class="data.item.loss ? 'text-danger':'text-success'"
            />
          </b-avatar>
        </div>
      </template>
      <!-- name -->
      <template #cell(name)="data">
        <div class="d-flex align-items-left">
          <b-avatar
            :style="backgroundColor(data.item.color)"
            class="mr-1"
          />
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.groupname }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- points -->
      <template #cell(points)="data">
        <div class="d-flex align-items-right">
          <span>{{ data.item.points }}</span>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>

import {
  BCard, BTable, BAvatar,
} from 'bootstrap-vue'
import Leaderboard from './Leaderboard'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const {
      TasksTableColumns,
      backgroundColor,

    } = Leaderboard()

    return {
      backgroundColor,
      TasksTableColumns,
    }
  },

}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
