<template>
  <section
    id="pricing-plan"
  >
    <b-row>
      <b-col cols="12">
        <web-heading />
      </b-col>
    </b-row>
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-0">
        Pricing Plans
      </h1>
      <p class="mb-2 pb-75">
        All plans include 40+ templates tools and features to boost your business. Choose the best plan to fit your needs.
      </p>
      <div class="d-flex align-items-center justify-content-center mb-5 pb-50">

        <b-form-radio-group
          v-model="contract"
          class="price-range-defined-radio-group"
          :options="packageOptions"
          @change="tooglePlan()"
        />
      </div>
    </div>
    <!--/ title text and switch button -->
    <b-row>
      <b-col
        sm="10"
        md="12"
        lg="8"
        class="mx-auto"
      >
        <b-row>
          <b-col
            cols="4"
            class="mb-2"
          >
            <b-input-group

              prepend="No of Users"
              class="input-group-merge"
              @change="handleCalculate()"
            >
              <b-form-input
                v-model="users"
                type="number"
              />
            </b-input-group>
          </b-col>
          <b-col
            cols="3"
            class="mb-2"
          >
            <b-button
              variant="primary"
              @click="handleCalculate()"
            >
              Calculate Price
            </b-button>

          </b-col>
          <b-col
            cols="4"
            class="mb-2"
          >
            <b-input-group
              prepend="SEK"
              class="input-group-merge"
            >
              <b-form-input
                :placeholder="totalPrice"
                readonly
              />
            </b-input-group>

          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="8"
        class="mx-auto"
      >
        <b-row>
          <b-col md="12">
            <b-card
              class="popular"
              align="center"
            >
              <div
                class="pricing-badge text-right"
              >
                <b-badge
                  v-if="annualPlanShow"
                  variant="light-primary"
                  pill
                >
                  popular
                </b-badge></div>
              <!-- img -->
              <b-img
                src="@/assets/images/illustration/Pot3.svg"
                class="mb-1"
                alt="svg img"
              />
              <!--/ img -->
              <h3>{{ contract }}</h3>
              <b-card-text>Årsavtal. Licens per månad med full funktionalitet. Inklusive drift, nya versioner och 2nd line support. Fritt antal spel (For small to medium businesses & big organizations)</b-card-text>

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item>
                  Vad kan gruppen göra för att nå ert mål  Vi hjälper er att formulera innehållet
                </b-list-group-item>
                <b-list-group-item>
                  Två workshops online för att definiera innehållet, ev med några användare
                </b-list-group-item>
                <b-list-group-item>
                  Bisevo skapar ert första spel och tävling
                </b-list-group-item>
                <b-list-group-item>
                  Ett lanseringstillfälle med användarna, online, 30 min
                </b-list-group-item>
                <b-list-group-item>
                  Ett uppföljningstillfälle med användarna eller cheferna
                </b-list-group-item>
                <b-list-group-item>
                  Grafisk anpassning med logga och en basfärg
                </b-list-group-item>
                <b-list-group-item>
                  Utbildning av era spelledare i att uppdatera och skapa nya spel
                </b-list-group-item>
                <b-list-group-item>
                  Vi kan importera användare från ert HR system, till exempel automatisk uppdatering varje vecka med nyanställda
                </b-list-group-item>
                <b-list-group-item>
                  Vi kan koppla till andra system för automatiska poäng
                </b-list-group-item>
              </b-list-group>

              <b-card-text><h3>Options</h3></b-card-text>

              <b-list-group class="list-group-circle text-left">
                <b-list-group-item>
                  Per workshop 15,000 SEK
                </b-list-group-item>
                <b-list-group-item>
                  Put the client company logo and colors 5,000 SEK
                </b-list-group-item>
                <b-list-group-item>
                  For learning and follow up 15,000 SEK
                </b-list-group-item>
                <b-list-group-item>
                  For System integration 30,000 SEK
                </b-list-group-item>
                <b-list-group-item>
                  For leadership training Contact US
                </b-list-group-item>
                <b-list-group-item>
                  For sales training Contact US
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->
              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2 mt-lg-3"
                variant="primary"
                :to="{ name: 'onboarding' }"
              >
                Next Step
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->

    <!-- pricing free trial -->
    <div class="pricing-free-trial">
      <b-row>
        <b-col
          lg="10"
          offset-lg="3"
          class="mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                Still not convinced? Start with a 14-day FREE trial!
              </h3>
              <h5>You will get full access to with all the features for 14 days.</h5>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mt-lg-3"
                :to="{ name: 'onboarding' }"
              >
                Start 14-day FREE trial
              </b-button>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->

    <!-- pricing faq -->
    <div class="pricing-faq">
      <h3 class="text-center">
        FAQ's
      </h3>
      <p class="text-center">
        Let us help answer the most common questions.
      </p>
      <b-row class="py-2">
        <b-col
          lg="10"
          offset-lg="2"
          class="mx-auto"
        >
          <app-collapse
            accordion
            type="margin"
          >

            <app-collapse-item
              v-for="(data,index) in pricing.qandA"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing faq -->
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge,
  BInputGroup, BFormInput, BFormRadioGroup,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import WebHeading from '../utility/WebHeading.vue'
import constants from '@/constants/static.json'

/* eslint-disable global-require */
export default {
  components: {
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    AppCollapseItem,
    AppCollapse,
    WebHeading,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      annualPlanShow: true,
      pricing: {},
      totalPrice: '',
      users: '',
      contract: constants.CONTRACT.ANNUAL_CONTRACT,
      packageOptions: [constants.CONTRACT.SIX_MONTHLY_CONTRACT, constants.CONTRACT.ANNUAL_CONTRACT, constants.CONTRACT.TWO_YEAR_CONTRACT],
    }
  },
  created() {
    this.$http.get('/pricing/data').then(res => { this.pricing = res.data })
  },
  methods: {
    tooglePlan() {
      if (this.contract === constants.CONTRACT.ANNUAL_CONTRACT) {
        this.annualPlanShow = true
      } else {
        this.annualPlanShow = false
      }
      this.handleCalculate()
    },
    handleCalculate() {
      // eslint-disable-next-line radix
      const users = parseInt(this.users)
      let total = 0
      const firstPrice = 385
      const secondPrice = 250
      const thirdPrice = 75
      const forthPrice = 50

      const firstUnits = 50
      const secondUnits = 200
      const thirdUnits = 1000
      const hundredAndFifty = 150
      const eightHundred = 800

      if (!this.isEmpty(users) && Number.isInteger(users)) {
        if (users > 0 && users <= firstUnits) {
          total = users * firstPrice
        } else if (users > firstUnits && users <= secondUnits) {
          total = firstUnits * firstPrice + (users - firstUnits) * secondPrice
        } else if (users > secondUnits && users <= thirdUnits) {
          total = firstUnits * firstPrice + hundredAndFifty * secondPrice + (users - secondUnits) * thirdPrice
        } else if (users > thirdUnits) {
          total = firstUnits * firstPrice + hundredAndFifty * secondPrice + eightHundred * thirdPrice + (users - thirdUnits) * forthPrice
        }
        if (this.contract === constants.CONTRACT.SIX_MONTHLY_CONTRACT) {
          total *= 1.3
        }
        if (this.contract === constants.CONTRACT.TWO_YEAR_CONTRACT) {
          total *= 0.8
        }
        total = Math.round((total + Number.EPSILON) * 100) / 100
        this.totalPrice = total.toString()
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            icon: 'EditIcon',
            variant: 'light-danger',
            text: 'Please enter no of users of the game',
          },
        })
      }
    },
    isEmpty(str) {
      return (!str || str.length === 0)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
