<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        target="_blank"
      >Bisevo</b-link>
      <span class="d-none d-sm-inline-block">, {{GetAppVersion}}</span>
    </span>

    <!-- <span class="float-md-right d-none d-md-block">Hand-crafted &amp; Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span> -->
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import constants  from "../../../constants/static.json";

export default {
  components: {
    BLink
  },

  computed: {
   
    GetAppVersion() {
      return constants.APP_VERSION;
    }
    
  },
}
</script>
