<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <b-row>
      <b-col cols="12">
        <web-heading />
      </b-col>
    </b-row>
    <b-card class="mb-0">

      <b-card-text class="mb-2">
        <h2>Gamification for business.🎮 🎯</h2>
      </b-card-text>
      <b-card-text class="mb-2">
        By combining behavioral science with elements from the gaming world, we have created Bisevo Gamification - a tool that helps businesses develop in a fun and simple way.
        You choose which area you want to improve and set measurable goals. Then it's just a matter of getting started - Bisevo Gamification guides you all the way from start to finish.
        Users can follow their own progress in our app, where each progress gives points that can then be converted into gift cards or other rewards.
        When the goal is reached, you have created new habits - and thus lasting change.What is your company's biggest challenge right now? What do you need to get better at to reach the next level? Is it sales, leadership, safety, quality... or maybe sustainability?. 🤟🏻
      </b-card-text>
      <b-card-text class="mb-2">
        If you don’t see this email in your inbox within 15 minutes, look for it in your junk mail folder or  Please let us know.
        Thanks,
      </b-card-text>
      <b-card-text class="mb-2">
        The bisevo Team
      </b-card-text>
      <div class="divider my-2">
        <social-media />
      </div>
    </b-card>

  </b-card>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol,
} from 'bootstrap-vue'

import WebHeading from '../utility/WebHeading.vue'
import SocialMedia from '../utility/SocialMedia.vue'

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    WebHeading,
    SocialMedia,
  },

}
</script>
