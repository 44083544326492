export default [
    {
      path: '/games/game-list',
      name: 'game-list',
      component: () => import('@/views/games/game-list/GameList.vue'),
      meta: {
        pageTitle: 'Game',
        breadcrumb: [
          {
            text: 'Game List',
            active: true
          }
        ]
      }
    },
    {
      path: '/games/game-configuration',
      name: 'game-configuration',
      component: () => import('@/views/games/game-configuration/GameConfiguration.vue'),
      meta: {
        pageTitle: 'Game Configuration',
        breadcrumb: [
          {
            text: 'Game List'
          },
          {
            text: 'Game Configuration',
            active: true
          }
        ]
      }
    },
    {
      path: '/games/game-team/:id',
      name: 'game-team',
      component: () => import('@/views/games/game-team/GameTeam.vue'),
      meta: {
        pageTitle: 'Game Team',
        breadcrumb: [
          {
            text: 'Game List'
          },
          {
            text: 'Game Team',
            active: true
          }
        ]
      }
    }
  ]
  