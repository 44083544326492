import moment from 'moment'
export const mixinList = {
  data(){
    return{
      DEFAULT_RETURN_STRING: "-",
      FES_DATE_FORMAT: "DD | MMM | YYYY",
      FES_DATE_DASH_FORMAT: "YYYY-MMM-DD",
      FES_DATE_SQL_FORMAT: "YYYY-MM-DD"
    }
  },
  methods: {
    format (date) {
      return moment(date).format(this.FES_DATE_FORMAT)
    },
    emptyData ( data) {
      if ( data) {
        return data;
      } else {
        return this.DEFAULT_RETURN_STRING;
      }
    },
    formatDate: function (date) {
      if (date) {
        return moment(date).format(this.FES_DATE_FORMAT);
      } else {
        return this.DEFAULT_RETURN_STRING;
      }
    },
    formatSqlDate: function (date) {
      if (date) {
        return moment(date).format(this.FES_DATE_SQL_FORMAT);
      } else {
        return this.DEFAULT_RETURN_STRING;
      }
    },
    formatDateDash: function (date) {
      if (date) {
        return moment(date).format(this.FES_DATE_DASH_FORMAT);
      } else {
        return this.DEFAULT_RETURN_STRING;
      }
    },
    formatTime: function (time) {
      if (time) {
        return moment(time, "HH:mm:ss").format("hh:mm A");
      } else {
        return this.DEFAULT_RETURN_STRING;
      }
    },
    ageValidations (date) {
      let age = new Date().getFullYear() - moment(date).year()
      return (age > 13 && age < 75)
    },
    componentToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },
    rgbToHex(r, g, b) {
      return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    }
  }
}
