import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import constants from '@/constants/static.json'

export default function Leaderboard() {
  // Use toast
  const toast = useToast()

  // Table Handlers
  const SetTableColumns = [
    { key: 'PLACE' },
    { key: 'NAME' },
    { key: 'XP' },
    { key: 'BADGE' },
  ]

  const TasksTableColumns = [
    { key: 'PLACE' },
    { key: 'NAME' },
    { key: 'POINTS' },
  ]

  const backgroundColor = color => `background-color:${color}`

  const batchUrl = index => {
    switch (index) {
      case 1:
        // eslint-disable-next-line global-require
        return require('@/assets/images/icons/gold.png')
      case 2:
        // eslint-disable-next-line global-require
        return require('@/assets/images/icons/silver.png')
      case 3:
        // eslint-disable-next-line global-require
        return require('@/assets/images/icons/bronze.png')
      case 4:
        // eslint-disable-next-line global-require
        return require('@/assets/images/icons/place.png')
      default:
        // eslint-disable-next-line global-require
        return require('@/assets/images/icons/gold.png')
    }
  }
  const sliceSortByValue = data => {
    const list = data.slice(0, constants.PAGES.LEADERBOARD_VIEW_PER_PAGE)
    list.sort((a, b) => b.points - a.points)
    // eslint-disable-next-line no-use-before-define
    return setPlace(list)
  }
  const setPlace = data => {
    const results = []
    data.forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      item.place = index + 1
      results.push(item)
    })
    return results
  }

  const fetchSets = (ctx, callback) => {
    store
      .dispatch('app-game/fetchSets', {
        id: router.currentRoute.params.id,
        currentdate: '2021-11-15',
      })
      .then(response => {
        callback(response.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching sets list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    SetTableColumns,
    TasksTableColumns,
    fetchSets,
    sliceSortByValue,
    backgroundColor,
    batchUrl,
  }
}
