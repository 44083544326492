<template>
  <section id="dashboard-ecommerce">
    <!-- Game -->
    <b-row class="match-height">
      <b-col xl="12">
&nbsp;
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col xl="12">
&nbsp;
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col xl="1" />
      <b-col
        xl="10"
        md="8"
      >
        <b-card
          v-if="hasGame"
          no-body
          class="card-statistics"
        >
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                xl="2"
                sm="10"
                class="mb-2 mb-xl-0"
              >
                <b-img
                  v-if="hasCompanyLogo"
                  :src="companyLogo"
                  alt="company logo"
                  width="150px"
                  height="90px"
                />
                <b-img
                  v-if="!hasCompanyLogo"
                  :src="require('@/assets/images/logo/logo.png')"
                  alt="company logo"
                  width="150px"
                  height="50px"
                />
              </b-col>

              <b-col
                xl="4"
                sm="10"
                class="mb-2 mb-xl-0 game"
                align="left"
              >
                <h2>{{ game.name }} >></h2>

                <b-row>
                  <b-col xl="6">
                    <b-media no-body>
                      <b-media-aside>
                        <b-avatar
                          size="24"
                          variant="light-info"
                          class="mr-0"
                        >
                          <feather-icon
                            size="12"
                            icon="WatchIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-card-text class="font-small-2">
                        {{ formatDate(game.startDate) }}
                      </b-card-text>
                    </b-media>
                  </b-col>

                  <b-col
                    xl="6"
                    class="date column"
                  >
                    <b-media no-body>
                      <b-media-aside>
                        <b-avatar
                          size="24"
                          variant="light-success"
                          class="game-end-icon"
                        >
                          <feather-icon
                            size="12"
                            icon="WatchIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-card-text class="font-small-2 mb-0">
                        {{ formatDate(game.endDate) }}
                      </b-card-text>
                    </b-media>
                  </b-col></b-row>
              </b-col>

              <b-col
                xl="4"
                sm="10"
              />
            </b-row>

            <b-row class="mt-1">
              <b-col xl="1" />
              <b-col
                xl="10"
                md="8"
                align="center"
              >
                <p v-html="game.visionStatement" />
              </b-col>
              <b-col xl="1" />
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="1" />
    </b-row>
    <!--/ Game -->

    <b-row
      v-if="hasGame"
      class="mb-2"
    >
      <b-col lg="1" />
      <b-col lg="10">
        <marquee>
          <span
            v-for="performedTask in performedTasks"
            :key="performedTask.performedtaskID"
            style="margin-right:100px"
          > {{ getTaskString(performedTask) }} </span>
        </marquee>
      </b-col>
      <b-col lg="1" />
    </b-row>

    <!--/ Sets Graph -->

    <!-- Sets & Task -->

    <b-row
      v-if="hasGame"
      class="match-height"
    >
      <b-col lg="1" />
      <b-col lg="4">
        <leaderboard-task :table-data="tasks" />
      </b-col>
      <b-col lg="6">
        <leaderboard-set
          :table-data="setResults"
          :game-data="game"
        />
      </b-col>
      <b-col lg="1" />
    </b-row>

    <!-- Sets Graph -->

    <b-row
      v-if="hasGame"
      class="match-height"
    >
      <b-col lg="1" />
      <b-col lg="10">
        <leaderboard-chart />
      </b-col>
      <b-col lg="1" />
    </b-row>
    <!--/ Sets & Task -->

    <b-row
      v-if="hasGame"
      class="match-height"
    >
      <!-- Footer Card -->
      <b-col lg="1" />

      <b-col lg="10">
        <span class="float-md-left d-block d-md-inline-block mt-25">
          COPYRIGHT © {{ new Date().getFullYear() }} Bisevo.se All rights
          Reserved
        </span>
      </b-col>
      <b-col lg="1" />
      <!--/ Footer Card -->
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BImg,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import LeaderboardSet from './LeaderboardSet.vue'
import LeaderboardTask from './LeaderboardTask.vue'
import LeaderboardChart from './LeaderboardChart.vue'
import LeaderboardPerformedTask from './LeaderboardPerformedTask.vue'
import Leaderboard from './Leaderboard'
import router from '@/router'
import store from '@/store'
import gameStore from '@/store/game/gameStore'
import { mixinList } from '@/constants/mixinValidations'
import constants from '@/constants/static.json'

// f700a7159c8885bc934cbf419fb6e643  07d60937c2560eb8af4ffa1f0e461de6  c472c3f6ee80e1b43420ca7cd7c862a8 b64eb0f4572b5119d9df7576b4d2a138

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BImg,
    BMediaAside,
    LeaderboardSet,
    LeaderboardTask,
    // eslint-disable-next-line vue/no-unused-components
    Leaderboard,
    LeaderboardChart,
    // eslint-disable-next-line vue/no-unused-components
    LeaderboardPerformedTask,
  },
  mixins: [mixinList],
  props: ['data'],
  data: () => ({
    game: {
      name: constants.COMPANY_NAME,
      startDate: '',
      endDate: '',
      visionStatement: '',
      status: '0',
      setIndex: '0',
    },
    hasGame: false,
    currentDate: new Date(),
    tasks: [],
    setResults: [],
    hasCompanyLogo: false,
    companyLogo: '',
    timer: '',
    performedTaskNewsLine: '',
    performedTasks: [],
  }),
  setup() {
    const { APP_STORE_MODULE } = constants
    if (!store.hasModule(APP_STORE_MODULE)) { store.registerModule(APP_STORE_MODULE, gameStore) }
    // UnRegister on game
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE)) { store.unregisterModule(APP_STORE_MODULE) }
    })

    const { sliceSortByValue } = Leaderboard()
    return { sliceSortByValue }
  },
  created() {
    this.fetchGame()
    this.fetchPerformedTask()
    this.timer = setInterval(this.fetchGame, constants.RELOAD_TIME)
    this.timer = setInterval(this.fetchPerformedTask, constants.RELOAD_TIME)
  },
  methods: {
    fetchGame() {
      if (router.currentRoute.params.id) {
        store
          .dispatch('app-game/fetchGame', router.currentRoute.params.id)
          .then(response => {
            if (response.data) {
              this.hasGame = true
              const { data } = response.data
              if (data.company_logo_url != 'zeromedia') {
                this.companyLogo = data.company_logo_url
                this.hasCompanyLogo = true
              }
              this.game.name = data.c_string_swe
              this.game.startDate = data.startdate
              this.game.endDate = data.enddate
              this.game.visionStatement = data.visionstatement
              this.fetchSets(this.game)
            }
          })
      }
    },
    fetchSets(gameData) {
      const { id } = router.currentRoute.params
      if (id) {
        store.dispatch('app-game/fetchSets', { id }).then(sets => {
          const currentSet = this.currentSet(gameData, sets.data)
          if (sets.data.length > 0 && gameData.status > 1) {
            store
              .dispatch('app-game/fetchPerformedGroup', {
                id,
                from: this.formatDateTime(currentSet.setFrom),
                to: this.formatDateTime(currentSet.setTo),
              }).then(performedTasks => {
                store.dispatch('app-game/fetchSetGameResult', {
                  id,
                  setId: currentSet.setId,
                }).then(setGameResult => {
                  if (setGameResult.data) {
                    this.setResults = this.sliceSortByValue(
                      setGameResult.data,
                    )
                  }
                  if (performedTasks.data) {
                    this.trendingUpDown(this.sliceSortByValue(performedTasks.data), this.setResults)
                  }
                })
              })
          }
        })
      }
    },
    fetchPerformedTask() {
      const vm = this
      const { id } = router.currentRoute.params
      if (id) {
        store.dispatch('app-game/fetchPerformedTask', { id }).then(task => {
          if (task.data) {
            vm.performedTasks = task.data
          }
        })
      }
    },
    getTaskString(data) {
      return `${data.firstname}.${data.lastname.charAt(0)} - ${this.diffInDaysString(data.datetime)} - ${data.habit} [${data.points}p]`
    },
    trendingUpDown(performedTasks, setResults) {
      performedTasks.forEach(element => {
        const result = setResults.find(x => x.groupID === element.groupID)
        if (typeof result !== 'undefined') {
          if (result.place === element.place) {
            // eslint-disable-next-line no-param-reassign
            element.loss = null
            // eslint-disable-next-line no-param-reassign
            element.trending = false
          } else if (result.place > element.place) {
            // eslint-disable-next-line no-param-reassign
            element.loss = false
            // eslint-disable-next-line no-param-reassign
            element.trending = true
          } else {
            // eslint-disable-next-line no-param-reassign
            element.loss = true
            // eslint-disable-next-line no-param-reassign
            element.trending = true
          }
        }
      })

      this.tasks = performedTasks
    },
    formatDateDash(date) {
      if (date) {
        return moment(date).format(constants.DATE_TIME.DASH)
      }
      return null
    },
    formatDateTime(date) {
      if (date) {
        return moment(date).format(constants.DATE_TIME.DASH_TIME)
      }
      return null
    },
    diffInDaysString(date) {
      const today = moment(moment(Date()).format(constants.DATE_TIME.DASH_TIME_SEC))
      const taskAt = moment(moment(date).format(constants.DATE_TIME.DASH_TIME_SEC))
      const diffInDays = today.diff(taskAt, 'days')
      if (diffInDays < 1) {
        const diffInHours = today.diff(taskAt, 'hours')
        if (diffInHours < 2) {
          const diffInMinutes = today.diff(taskAt, 'minutes')
          return `${diffInMinutes} minutes ago`
        }
        return `${diffInHours} hours ago`
      }
      return `${diffInDays} days ago`
    },
    currentSet(gameData, setData) {
      const currentDate = this.formatDateDash(this.currentDate)
      const results = []
      let isfounded = false
      let resultSet = {}
      let setId = 0
      if (gameData.startDate > currentDate) {
        // eslint-disable-next-line no-param-reassign
        gameData.status = '1' // pending
        // eslint-disable-next-line no-param-reassign
        gameData.setIndex = 0
      } else {
        // eslint-disable-next-line no-param-reassign
        gameData.status = '4' // finish
        setId = setData[setData.length - 1].settime_id
        // eslint-disable-next-line no-param-reassign
        gameData.setIndex = setData.length
      }
      setData.forEach((item, index) => {
        // eslint-disable-next-line eqeqeq
        if (item.settime > currentDate && !isfounded && gameData.status != 1) {
          if (index === 0) {
            isfounded = true
            // eslint-disable-next-line no-param-reassign
            gameData.status = '2' // started
            // eslint-disable-next-line no-param-reassign
            gameData.setIndex = 0
          } else {
            results.push(setData[index - 1])
            isfounded = true
            // eslint-disable-next-line no-param-reassign
            gameData.status = '3' // ongoing
            // eslint-disable-next-line no-param-reassign
            gameData.setIndex = 1
            // eslint-disable-next-line no-param-reassign
            gameData.setIndex = index
          }
        }
      })

      if (results.length == 0) {
        resultSet = {
          setId,
          setFrom: gameData.startDate,
          setTo: gameData.endDate,
          setIndex: gameData.setIndex,
        }
      } else {
        resultSet = {
          setId: results[0].settime_id,
          setFrom: results[0].settime,
          setTo: this.currentDate,
          setIndex: gameData.setIndex,
        }
      }
      return resultSet
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

@media only screen and (min-width: 1500px) {
  td {
    font-size: 22px;
  }
}
.column {
  padding: 0px !important;
}

marquee {
    font-family: sans-serif;
    font-size: 18px;
    width: 100%;
    padding: 10px 0;
    background-color:#F76535;
    color : white;
}
@media only screen and (min-width: 2100px) {
  .game {
    margin-left: -200px;
  }
  .column {
    margin-left: -250px !important;
  }
}
</style>
