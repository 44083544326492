<template>
  <b-card

    no-body
    class="card-revenue-budget"
  >
    <b-row>
      <b-col
        md="12"
        lg="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            Set Report
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
              <span>Points</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="set-report-chart"
          type="bar"
          height="230"
          :options="chartOptions"
          :series="dataSeries.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import { onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import gameStore from '@/store/game/gameStore'
import constants from '@/constants/static.json'
import Leaderboard from './Leaderboard'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    Leaderboard,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timer: '',
      xaxisData: [],
      dataSeries: {
        series: [
          {
            name: 'Task',
            data: [],
          },
        ],
      },
      chartOptions: {
        chart: {
          stacked: true,
          type: 'bar',
          toolbar: { show: false },
        },
        grid: {
          padding: {
            top: -20,
            bottom: -10,
          },
          yaxis: {
            lines: { show: false },
          },
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: [$themeColors.primary, $themeColors.warning],
        plotOptions: {
          bar: {
            columnWidth: '40%',
          },
          distributed: true,
        },
        yaxis: {
          labels: {
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
        },
      },
      // },
    }
  },
  setup() {
    const { APP_STORE_MODULE } = constants
    // Register module
    if (!store.hasModule(APP_STORE_MODULE)) store.registerModule(APP_STORE_MODULE, gameStore)
    // UnRegister on game
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE)) store.unregisterModule(APP_STORE_MODULE)
    })
  },

  created() {
    this.generateReport()
    this.timer = setInterval(this.generateReport, constants.RELOAD_TIME)
  },
  methods: {
    updateAxis(data) {
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: data,
        },
      }
    },
    generateReport() {
      const { id } = router.currentRoute.params
      const vm = this
      const categoriesData = []
      vm.dataSeries.series[0].data = []
      if (id) {
        store.dispatch('app-game/fetchSetResult', { id }).then(setResults => {
          setResults.data.forEach((item, index) => {
            categoriesData.push(`Set - ${index + 1}`)
            vm.dataSeries.series[0].data.push(item.points)
          })
          vm.updateAxis(categoriesData)
        })
      }
    },
  },

}
</script>
