import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    forgotpassword(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_URL + '/user/forgotpassword', {email : data }  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
  },
}
