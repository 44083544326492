<template>
  <div>
    <div>

      <div class="misc-inner p-2 p-sm-3 bg-orange">

        <div class="w-100 text-center">
          <b-img
            fluid
            :src="require('@/assets/images/logo/bisevowhite.png')"
            alt="logo img"
            width="150px"
            height="60px"
          />

        </div>

        <div class="w-100 text-center">
          <h3 class="mb-1 page-heading-2">
            Welcome to {{ game.gameName }}  Gamification
          </h3>

        </div>

        <div class="w-100 text-center">
          <p class="mb-1 page-description-samll">
            You are invited to participate in this game! Hope you will enjoy it!
            <br>
            Download your app here!
          </p>
          <b-link
            target="_blank"
            href="https://play.google.com/store/apps/details?id=se.fingerfunk.bisevo1&hl=sv"
          >
            <b-img
              fluid
              :src="googleImg"
              width="150px"
              height="60px"
              class="center-img"
              alt="landing page"
            />
          </b-link>

          <b-link
            target="_blank"
            href="https://itunes.apple.com/us/app/gamechanger-bisevo/id1284960386?mt=8"
          >
            <b-img
              fluid
              :src="imgAppleUrl"
              width="136px"
              height="60px"
              alt="landing page"
            />
          </b-link>

        </div>

        <br>

        <div class="w-100 text-center img-container">
          <!-- img -->
          <b-img
            fluid
            class="center-img"
            :src="require('@/assets/images/pages/utskick maj_2022-43-44.png')"
            alt="landing page"
          />

        </div>

      </div>
    <!-- /Main Page-->
    </div>
    <b-row>
      <b-col class="col-md-4 col-first" />
      <b-col class="col-md-4 pt-3 col-second">
        <div class="w-100 text-center pb-2 pt-5" />

        <h1 class="big-title text-center w-100">
          <strong>Happy gaming !</strong>
        </h1>

      </b-col>

    </b-row>
    <div class="about" />
    <div>
      <div>
        <b-row>
          <b-col
            lg="10"
            offset-lg="3"
            class="mx-auto"
          >
            <div />
          </b-col>
        </b-row>
      </div>
    </div>

  </div>

</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg, BRow, BCol,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { mixinList } from '@/constants/mixinValidations'
import constants from '@/constants/static.json'
import gameStore from '@/store/game/gameStore'

export default {
  components: {
    BLink,
    BImg,
    BRow,
    BCol,
  },
  mixins: [mixinList],
  // eslint-disable-next-line vue/require-prop-types
  props: ['data'],
  data: () => ({
    logoImg: require('@/assets/images/logo/bisevowhite.png'),
    googleImg: require('@/assets/images/pages/google_badge.svg'),
    appleImg: require('@/assets/images/pages/ios_badge.svg'),
    game: {
      gameName: constants.COMPANY_NAME,
      startDate: '',
      endDate: '',
      visionStatement: '',
    },
    isGame: false,

  }),

  setup() {
    const { APP_STORE_MODULE } = constants
    // Register module
    if (!store.hasModule(APP_STORE_MODULE)) store.registerModule(APP_STORE_MODULE, gameStore)
    // UnRegister on game
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE)) store.unregisterModule(APP_STORE_MODULE)
    })
  },
  computed: {
    imgLogoUrl() {
      return this.logoImg
    },
    imgAppleUrl() {
      return this.appleImg
    },
  },
  created() {
    this.fetchGame()
  },
  methods: {
    fetchGame() {
      if (router.currentRoute.params.id) {
        store.dispatch('app-game/fetchGame', router.currentRoute.params.id).then(response => {
          if (response.data) {
            const { data } = response.data
            this.isGame = true
            this.game.gameName = data.c_string_swe
            this.game.startDate = data.startdate
            this.game.endDate = data.enddate
            this.game.visionStatement = data.visionstatement
          }
        })
      }
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.img {
  max-width: 40%;
  margin-top: -20px;
}
@media only screen and (min-width: 1300px) {
  .img {
  max-width: 50%;
  margin-top: -20px;
}
}
@media only screen and (max-width: 1300px) {
  .img {
  max-width: 80%;
  margin-top: -20px;
}
}
@media only screen and (max-width: 700px){
  .col-first {
    display: none;
  }
  .col-third {
    display: none;
  }
  .col-second {
    padding: 0px 25px;
  }
}
@media only screen and (min-width: 1500px) {
  td {
    font-size: 22px;
  }
}
@media only screen and (max-width: 1500px) {
  .big-title {
    margin-top: -60px;
  }
}

</style>
