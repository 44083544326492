<template>
  <div>
    <div>
      <!-- social media -->
        <div class="divider my-2">
          <div class="divider-text">
            follow us on social media
          </div>
        </div>
        <!-- social button -->
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="https://www.linkedin.com/company/bisevo-business-evolution-ab/"
            variant="linkedin" target="_blank"
          >
            <feather-icon icon="LinkedinIcon" />
          </b-button>
        </div>
    </div>
  </div>
</template>

<script>
import {
  BButton
} from 'bootstrap-vue'

export default {
  components: {
    BButton
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
