<template>
  <div>
    <form-wizard
      color="#FF6400"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="steps-transparent mb-3"
      @on-complete="formSubmitted"
    >
      <!-- account detail tab -->
      <tab-content
        title="Account Details"
        icon="feather icon-user"
        :before-change="validationForm"
      >

        <validation-observer
          ref="accountRules"
          tag="form"
        >

          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Account Details
              </h5>
              <small class="text-muted">
                Enter Your Account Details.
              </small>
            </b-col>

            <!-- Field: First Name -->

            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="First Name"
                label-for="i-firstname"
              >

                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    id="i-firstname"
                    v-model="user.firstname"
                    placeholder="Niklas"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: Last Name -->

            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Last Name"
                label-for="i-lastname"
              >

                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <b-form-input
                    id="i-lastname"
                    v-model="user.lastname"
                    placeholder="Wörmann"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: Email -->

            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Email"
                label-for="i-email"
              >

                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="i-email"
                    v-model="user.email"
                    placeholder="niklas.wörmann@bisevo.se"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: Language -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Language"
                label-for="language"
              >
                <v-select
                  :options="languageOptions"
                  :clearable="false"
                  input-id="language"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Mobile -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Mobile"
                label-for="i-mobile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mobile"
                  rules="required"
                >
                  <b-form-input
                    id="i-mobile"
                    v-model="user.mobile"
                    placeholder="0773777200"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: Contact Options -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Contact Options"
                label-for="contact-options"
                label-class="mb-1"
              >
                <b-form-checkbox-group
                  id="contact-options"
                  v-model="user.contact_option"
                  :options="contactOptionsOptions"
                />
              </b-form-group>
            </b-col>

          </b-row>

          <b-row>

            <!-- Field: Company Name-->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Company Name"
                label-for="i-companyname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company Name"
                  rules="required"
                >
                  <b-form-input
                    id="i-companyname"
                    v-model="company.companyname"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: website -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Website"
                label-for="i-website"
              >
                <b-form-input
                  id="i-website"
                  v-model="company.website"
                  placeholder="https://bisevo.se"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Postcode -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Nature of business"
                label-for="i-natureofbusiness"
              >
                <b-form-input
                  id="i-natureofbusiness"
                  v-model="company.nature_of_business"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Form: Address Info Form -->
          <b-row>

            <!-- Field: Address Line 1 -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Address Line 1"
                label-for="address-line-1"
              >
                <b-form-input
                  id="address-line-1"
                  v-model="company.address_line_1"
                  placeholder="Kistagången 12"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Address Line 2 -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Address Line 2"
                label-for="address-line-2"
              >
                <b-form-input
                  id="address-line-2"
                  v-model="company.address_line_2"
                  placeholder="Kista"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Postcode -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Postcode"
                label-for="postcode"
              >
                <b-form-input
                  id="postcode"
                  v-model="company.postcode"
                  type="number"
                  placeholder="164 40"
                />
              </b-form-group>
            </b-col>

            <!-- Field: City -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="City"
                label-for="city"
              >
                <b-form-input
                  id="city"
                  v-model="company.city"
                  placeholder="Stockholm"
                />
              </b-form-group>
            </b-col>

            <!-- Field: State -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="State"
                label-for="state"
              >
                <b-form-input
                  id="state"
                  v-model="company.state"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Country -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Country"
                label-for="country"
              >
                <b-form-input
                  id="country"
                  v-model="company.country"
                  placeholder="Sweden"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </validation-observer>

      </tab-content>
      <!-- game details -->
      <tab-content
        title="Game Info"
        icon="feather icon-award"
        :before-change="validationGameInfo"
      >
        <validation-observer
          ref="gameRules"
          tag="form"
        >
          <b-row>

            <!-- Field: Game Name -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Game Name"
                label-for="i-gamename"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Game Name"
                  rules="required"
                >
                  <b-form-input
                    id="i-gamename"
                    v-model="game.gamename"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: Start Date -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Game Start Date"
                label-for="i-startdate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Game Start Date"
                  rules="required"
                >
                  <cleave
                    id="i-startdate"
                    v-model="game.startdate"
                    class="form-control"
                    placeholder="YYYY-MM-DD"
                    :raw="false"
                    :options="options.date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: End Date -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Game End Date"
                label-for="i-enddate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Game End Date"
                  rules="required"
                >
                  <cleave
                    id="i-enddate"
                    v-model="game.enddate"
                    class="form-control"
                    placeholder="YYYY-MM-DD"
                    :raw="false"
                    :options="options.date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Category Template -->

            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Category"
                label-for="i-category"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Category"
                  rules="required"
                >
                  <v-select
                    v-model="category"
                    :options="categoryOptions"
                    :clearable="false"
                    label="name"
                    input-id="i-category"
                    @input="fetchTemplates($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Game Template -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Template"
                label-for="i-template"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Template"
                  rules="required"
                >
                  <v-select
                    v-model="template"
                    :options="templateOptions"
                    :clearable="true"
                    label="name"
                    input-id="i-template"
                    @input="fetchTemplatesTask($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Vision statement -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Vision statement"
                label-for="i-visionstatement"
              >
                <b-form-input
                  id="i-visionstatement"
                  v-model="game.visionstatement"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </validation-observer>
      </tab-content>

      <!-- template -->
      <tab-content
        title="Templates"
        icon="feather icon-clipboard"
      >

        <b-card-code :title="template.name">
          <b-media
            v-for="(task, index) in templatesTask"
            :key="index"
          >
            <template #aside>

              <feather-icon
                icon="CheckSquareIcon"
                size="30"
              />

            </template>

            <h5 class="mt-0">
              {{ task.taskbeskrivning }}
            </h5>
            <b-card-text>
              --> description of the task
            </b-card-text>

            <b-media
              v-for="(subTask, index) in task.template_task_subtasks"
              :key="index"
              class="mt-3"
            >
              <template #aside>
                <feather-icon
                  icon="CheckCircleIcon"
                  size="30"
                />
              </template>

              <h5 class="mt-0">
                {{ subTask.template_subtask.subtaskstring }}
              </h5>
              <b-card-text class="mb-0">
                --> description of the sub task
              </b-card-text>
            </b-media>
          </b-media>

        </b-card-code>

      </tab-content>

      <!-- payment -->
      <tab-content
        title="Payment"
        icon="feather icon-credit-card"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Payment Details
            </h5>
            <small class="text-muted">Enter Your Payment Details</small>
          </b-col>

          <!-- Name of the card -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Name on Card"
              label-for="i-nameofthecard"
            >
              <b-form-input
                id="i-nameofthecard"
              />
            </b-form-group>
          </b-col>

          <!-- credit card -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Credit / Debit / ATM Card"
              label-for="i-cardNumber"
            >
              <cleave
                id="i-cardNumber"
                v-model="form.cardNumber"
                class="form-control"
                :raw="false"
                :options="options.creditCard"
                placeholder="0000 0000 0000 0000"
              />
            </b-form-group>
          </b-col>

          <!-- CVV -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="CVV/Security code"
              label-for="i-cvv"
            >
              <b-form-input
                id="i-cvv"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Expiry date -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Expiry Date"
              label-for="i-enddate"
            >
              <cleave
                id="i-enddate"
                v-model="dateDefault"
                class="form-control"
                placeholder="YYYY-MM"
                :raw="false"
                :options="options.dateMonth"
              />
            </b-form-group>
          </b-col>

          <!-- ZIP Postal code -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="ZIP/Postal Code"
              label-for="i-zipcode"
            >
              <b-form-input
                id="i-zipcode"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import BCardCode from '@core/components/b-card-code'
import Cleave from 'vue-cleave-component'
import { required, email } from '@validations'
import { onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckboxGroup,
  BMedia,
  BCardText,
} from 'bootstrap-vue'
import store from '@/store'
import gameStore from '@/store/game/gameStore'
import constants from '@/constants/static.json'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormCheckboxGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BCardText,
    BCardCode,
    Cleave,
  },
  data() {
    return {

      game: {
        gamename: 'Bisevo 2',
        startdate: '',
        enddate: '',
        category_id: '',
        template_id: '',
        visionstatement: '',
      },
      company: {
        companyname: 'Bisevo',
        nature_of_business: '',
        package_id: 2,
        website: '',
        address_line_1: '',
        address_line_2: '',
        postcode: '',
        city: '',
        state: '',
        country: '',
      },
      user: {

        firstname: 'Nikals',
        lastname: 'Wörmann',
        language: '',
        contact_option: [],
        mobile: '0773777100',
        email: 'nikals@bisevo.se',
      },
      category: '',
      template: '',
      email,
      required,
      dateDefault: null,
      languageOptions: [
        'English',
        'Swedish',
        'French',
        'German',
        'Spanish',
      ],
      teamOptions: ['One Team Only', 'Define maximum users per team'],
      contactOptionsOptions: ['Email', 'Message', 'Phone'],
      categoryOptions: [],
      templateOptions: [],
      templatesTask: [],
      form: {
        cardNumber: null,
        date: null,
        phone: null,
      },
      options: {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        dateMonth: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+46',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      },
    }
  },
  setup() {
    const { APP_STORE_MODULE } = constants

    // Register module
    if (!store.hasModule(APP_STORE_MODULE)) store.registerModule(APP_STORE_MODULE, gameStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE)) store.unregisterModule(APP_STORE_MODULE)
    })
  },
  created() {
    this.game.startdate = moment().add('days', 1).format(constants.DATE_TIME.DASH)
    this.game.enddate = moment().add('days', 14).format(constants.DATE_TIME.DASH)
    this.fetchCategory()
  },
  methods: {
    fetchCategory() {
      store.dispatch('app-game/fetchCategory').then(category => {
        if (category.data) {
          this.categoryOptions = category.data.data.allResult
        }
      })
    },
    fetchTemplates(e) {
      this.templateOptions = []
      if (e.ID) {
        store.dispatch('app-game/fetchTemplates', { id: e.ID }).then(templates => {
          if (templates.data) {
            this.templateOptions = templates.data.data.allResult
          }
        })
      }
    },
    fetchTemplatesTask(e) {
      this.templateOptions = []
      if (e.ID) {
        store.dispatch('app-game/fetchTemplatesTask', { id: e.ID }).then(templatesTask => {
          if (templatesTask.data) {
            this.templatesTask = templatesTask.data.data.allResult
          }
        })
      }
    },
    formSubmitted() {
      this.game.company = this.company
      this.game.user = this.user
      this.game.category_id = this.category.ID
      this.game.template_id = this.template.ID

      store.dispatch('app-game/addOnboarding', this.game)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              icon: 'EditIcon',
              variant: 'success',
              text: 'You have successfully create competition!',
            },
          })
          this.$router.push({ name: 'onboarding-confirmation' })
        })
        .catch(error => {
          if (error.response.data.data.status === 'failed') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                icon: 'EditIcon',
                variant: 'light-danger',
                text: error.response.data.data.message.toString(),
              },
            })
          }
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationGameInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.gameRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

  },
}
</script>
