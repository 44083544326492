export default [
  {
    header: 'Game Changer',
  },
  {
    title: 'Company',
    route: 'company',
    icon: 'CopyIcon',
  },
  {
    title: 'User',
    route: 'user',
    icon: 'PackageIcon',
  },
  {
    title: 'Game22222',
    route: 'game',
    icon: 'CheckCircleIcon',
  },
  {
    title: 'Activity',
    route: 'activity',
    icon: 'CopyIcon',
  },
  {
    title: 'KPI',
    route: 'kpi',
    icon: 'ServerIcon',
  },
  {
    title: 'Activity Template',
    route: 'activity-templa',
    icon: 'GridIcon',
  },
  {
    title: 'Media',
    route: 'media',
    icon: 'GridIcon',
  },
  {
    title: 'Reports',
    route: 'reports',
    icon: 'GridIcon',
  },
]
